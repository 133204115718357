@import '../../styles/colors.scss';

.players-container {
  .player-attributes-section {
    display: flex;
    padding: 10px 0px;
    border-radius: 3px;
    .form {
      display: flex;
      flex-direction: column;
      .form-section {
        border: none;
        margin: 0;
        padding: 0 0 20px 0;
      }
      h3 {
        padding: 0
      }
    }
    .flag-uploader {
      margin-left: 40px;
      height: 300px;
    }
  }
  .player-actions {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .player-attributes-section {
    margin-top: 20px;
    .player-tree-view {
      width: 320px;
      margin-right: 40px;
      input {
        width: 100%;
        margin: 5px 0 10px;
      }
      .tree-view {
        height: calc(100vh - 240px);
        overflow: auto;
        border-right: 1px solid $ultra-light-grey;
        padding: 10px 20px;

        li {
          margin: 5px 0;
          cursor: pointer;
        }

        li.checked {
          color: #40a9ff;
          font-weight: bold;
        }
      }
    }
    .attributes {
      flex-direction: column;
      .flag-uploader {
        margin-left: 0;
      }
    }

    .player-tabs-section {
      margin: -5px;
      width: 775px;

      .ant-tabs {
        width: 775px !important;
      }
    }
  }

  input {
    border: 1px solid #d9d9d9 !important;
  }
}