@import '../../styles/colors.scss';

.teams-prediction-tables {
  margin-top: 20px;
  width: 100%;
  padding-right: 40px;
}

.prediction-table {
  margin-bottom: 40px;

  .table-header {
    background-color: $table-header-color;

    &:first-child td,
    &:first-child th {
      font-weight: 600;
      font-size: 12px;
      line-height: 22px;
    }
  }

  th {
    font-weight: 600;
  }

  td {
    font-weight: 400;
  }

  td,
  th {
    border: 1px solid $border-input-color;
    text-align: center;
    padding: 2px 6px;
    font-size: 12px;
    line-height: 22px;
    white-space: nowrap;

    &.date {
      min-width: 90px;
    }

    &.name,
    &.team {
      min-width: 200px;
    }
  }

  td {
    &.name,
    &.team,
    &.home_team,
    &.away_team,
    &.score {
      text-align: left;
    }
  }
}

.sbdocs.sbdocs-content {
  width: max-content;

  .css-1dm44cd {
    width: max-content;
  }
}
