@import '../../styles/colors.scss';

.form {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  h3 {
    width: 100%;
    padding: 40px 0 0 0;
  }

  h4 {
    width: 342px;
    display: flex;
    justify-content: space-between;
  }

  .form-section {
    border: 1px solid $ultra-light-grey;
    margin: 0 20px 20px 0;
    padding: 20px;

    &-column {
      display: inline-block;
      width: 176px;
      color: $form-labels-color !important;
      font-size: 13px;
    }
  }

  .relative-form {
    position: relative;
    width: 300px;

    .ant-calendar-picker-clear, .ant-calendar-picker-icon {
      top: 30% !important
    }

    .ant-form.ant-form-horizontal {
      transform: translate(-50%, -0%) !important;
    }
  }

  .form-selects-wrapper {
    display: flex;
    flex-direction: column;
    margin: 10px 0px;
    justify-content:center;
    align-items: center;
    width: 300px;
    
    .form-selects-wrapper-row {
      flex: 1;
      width: 100%;
      max-width: 256px;
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;

      label {
        font-weight: 500;
        margin-bottom: 5px;
        color: $color-dark-grey !important;
        display: flex;
        justify-content: space-between;
      }

      .ant-select {
        min-width: 256px;
      }


    .filter-tree-selector {
      display: flex;
      justify-content: space-between;
      border: 1px solid $border-input-color;
      height: 32px;
      padding: 0 11px;
      line-height: 30px;
      cursor: pointer;
      background-color: #fff;
      border-radius: 3px;
      overflow: hidden;
      font-size: 12px;

      .anticon {
        font-size: 12px;
        opacity: 0.5;
        display: flex;
        align-items: center;
      }
    }
    }

    .select-info-error {
      font-weight: 600;
      color: $color-error;
      padding: 5px 0;
    }
  }

  .label-colored-row {
    label {
      color: $color-dark-grey !important;
    }
  }

  .form-row {
    display: flex;
    flex-direction: row;
    margin: 10px 0px;

    label {
      display: flex;
      align-items: center;
      margin-right: 10px;
      font-size: 11px;
      width: 120px;
      text-transform: uppercase;
      padding-top: 4px;
    }

    div {
      font-size: 15px;

      &.small {
        input,
        [class$=input-wrap] {
          width: 80px;
        }
      }
    }

    .divider {
      width: 16px;
      display: flex;
      align-self: center;
      justify-content: center;
    }

    .ant-select {
      min-width: 160px;
    }

    input {
      width: 160px;
      font-size: 15px;

      &.small {
        width: 80px;
      }

      &.big {
        width: 280px;
      }
    }

    .ant-select {
      &.big {
        width: 280px;
      }
    }

    .delete-row {
      margin-left: 20px;
    }
  }
}

.forms-inline-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.tree-select-input {
  position: relative;
  width: 100%;
 .tree-selector {
   display: flex;
   justify-content: space-between;
   border: 1px solid $border-input-color;
   height: 32px;
   padding: 0 11px;
   line-height: 30px;
   cursor: pointer;
   background-color: #fff;
   border-radius: 3px;
   overflow: hidden;
   font-size: 12px;
   .anticon {
     font-size: 12px;
     opacity: 0.5;
     display: flex;
     align-items: center;
   }
 }
 
 .tree-dropdown {
   z-index: 99;
   position: absolute;
   width: 100%;
   top: 100%;
   background-color: #fff;
   border-radius: 3px;
   border: 1px solid $border-input-color;
   max-height: 300px;
   overflow-y: auto;
 }

  .tree-view {
   border-radius: 0 0 3px 3px;
   padding-bottom: 10px;
   .ant-tree {
     padding-left: 5px;
     border-radius: 0 0 3px 3px;
     overflow-y: hidden;
     overflow-x: auto;
     font-size: 12px;
     li {
       &:last-child {
         border-radius: 0 0 3px 3px;
       }
     }
   }
 }
}
