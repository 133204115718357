@import '../../styles/colors.scss';


.configuration-container {
  .area-attributes-section,
  .club-attributes-section {
    display: flex;
    padding: 10px 0px;
    margin: 20px 60px 0 0;
    border-radius: 3px;

    .form {
      display: flex;
      flex-direction: column;

      .form-section {
        border: none;
        margin: 0;
        padding: 0 0 20px 0;
      }

      h3 {
        padding: 0;
      }
    }

    .flag-uploader {
      margin-left: 40px;
      height: 300px;
    }
  }

  .club-attributes-section {
    margin-top: 20px;

    .club-tree-view {
      width: 320px;
      margin-right: 40px;

      input {
        width: 240px;
        margin-bottom: 10px;
      }

      .tree-view {
        height: calc(100vh - 240px);
        overflow: auto;
        border-right: 1px solid $ultra-light-grey;
        padding: 20px;
      }
    }

    .attributes {
      flex-direction: column;

      .flag-uploader {
        margin-left: 0;
        margin-top: 20px;
      }
    }

    .form-content {
      border-right: none;
      border-bottom: 1px solid $ultra-light-grey;
    }
  }
}
