@import '../../styles/colors.scss';

.uploader-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}

.upload-section {
  display: flex;

  .ant-menu {
    border: none;
    margin-top: -4px;
    margin-right: 4px;
  }

  .file-size-1 {
    .ant-upload-drag-container img {
      width: auto;
      height: 150px;
      object-fit: cover;
    }
  }

  .file-size-2 {
    .ant-upload-drag-container img {
      height: 64px;
      width: 64px;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  .file-size-3 {
    .ant-upload-drag-container img {
      width: auto;
      height: 18px;
      object-fit: cover;
    }
  }

  .ant-upload {
    &.ant-upload-drag {
      width: 252px;
      height: 152px;
      border: 0.5px solid $white-with-light-grey;
      background-color: #FFF;

      .ant-upload {
        padding: 0;
      }

      i {
        font-size: 36px;
        color: $loader-bar-color;
      }

      .ant-upload-drag-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
      }
    }
  }

  .uploader {
    position: relative;
    margin-bottom: 12px;
  }

  .btns-container {
    display: flex;
    flex-direction: row;
    margin-top: 25px;
    justify-content: space-between;
    width: 250px;
    font-family: 'PingFangSC-Regular';

    .remove-btn {
      width: 100px;
    }
  }

  .ant-menu.ant-menu-light.ant-menu-root.ant-menu-horizontal {
    text-align: left;
    margin-bottom: 20px;
    border: none;

    .ant-menu-item {
      border: none;

      &.ant-menu-item-selected {
        border-bottom: 2px solid $selected;
        color: $selected;
      }
    }
  }

  .file-decription {
    font-family: 'PingFangSC-Regular';
    font-size: 14px;
    line-height: 22px;
    color: $form-labels-color;
    margin-top: 0px;
  }

  .ant-upload.ant-upload-drag p.ant-upload-text {
    font-size: 12px;
  }
}

