@import '../../styles/colors.scss';
@import '../../styles/rwd.scss';

.header {
  background-color: $header-bg-color;
  display: flex;
  justify-content: space-between;
  height: 57px;

  .logo-container {
    width: 239px;
    min-width: 239px;
    height: 22px;
    display: flex;
    align-self: center;
    justify-content: center;
    margin: 0;

    .logo {
      width: 136px;
      height: 22px;
    }
  }
}

.ant-menu-horizontal > .ant-menu-submenu {
  padding-top: 13px;
}

.ant-menu.ant-menu-light.ant-menu-root.ant-menu-horizontal {
  width: 100%;
  max-width: calc(100vw - 240px);
  padding-left: 24px;
  text-align: left;
  border-bottom: 1px solid $light-menu-item-color;
  border-left: 1px solid $ultra-light-grey;
  margin-top: -5px;

  .ant-menu-item {
    font-family: 'PingFangSC-Regular';
    font-size: 14px;
    line-height: 1.57;
    padding: 12px 15px;
    margin: 0 10px;
    color: $main-font-color;

    &:hover,
    &.ant-menu-item-selected {
      border-bottom-color: $selected-color;
    }

    a.disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }

    &.logout-btn {
      border-bottom: none;
      position: absolute;
      right: 0;

      button {
        color: #fff;
        background-color: $color-dark-grey;
      }
    }
  }
}

.ant-menu.ant-menu-light.ant-menu-root.ant-menu-inline {
  span {
    text-align: center;
  }

  .ant-btn {
    left: 50%;
    transform: translate(-50%);
  }
}

@include breakpoint(lg) {
  .ant-menu.ant-menu-light.ant-menu-root.ant-menu-horizontal {
    .ant-menu-item {
      padding: 12px 10px;
    }
  }
}
