@import '../../styles/colors.scss';

.demo-container {
  .demo-fixtures-section {
    display: flex;
    flex-direction: row;
    padding-bottom: 100px;
  }

  .week {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-right: 20px;

    .day {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      border: 1px solid $ultra-light-grey;
      width: 340px;
      margin: 10px 0 5px;
      cursor: pointer;

      .day-date {
        padding: 10px;
        border-right: 1px solid $ultra-light-grey;
        background-color: $ultra-light-grey;
        width: 100%;
        display: flex;
        justify-content: space-between;

        span {
          display: block;
          font-size: 10px;
          white-space: nowrap;
          font-weight: 700;
        }

        .day-delete {
          font-weight: 900;
          font-size: 11px;
          cursor: pointer;
          color: #c00;
        }
      }

      .day-match {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        border-bottom: 1px solid $ultra-light-grey;
        width: 100%;

        &.active {
          background-color: rgb(33, 144, 255, 0.3);
        }
      }

      &.active {
        border: 1px solid $selected;

        .day-date {
          background-color: $selected;
          color: #fff;
        }
      }

      span {
        display: block;
        font-size: 11px;
        white-space: nowrap;

        &.match {
          padding: 10px;
          width: 100%;
          text-align: center;
          position: relative;

          &.original {
            display: block;
            font-weight: 700;
            font-size: 9px;
            padding-bottom: 5px;

            &:after {
              content: '\2193';
              text-align: center;
              display: flex;
              justify-content: center;
              margin-top: 5px;
            }

            & + .match {
              padding-top: 0;
            }
          }
        }
      }
    }
  }

  .fixtures-container {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    border-left: 1px solid $sidebar-border-color;
    width: 100%;

    .random-generator {
      margin-bottom: 20px;
    }

    .filters {
      background-color: $sidebar-border-color;
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;

      .filters-row {
        width: 30%;

        label .filter-clear {
          display: none;
        }
      }

      .button-container {
        margin-top: 10px;
        text-align: center;
        padding-bottom: 20px;
      }
    }

    .ant-table-thead > tr > th {
      text-align: center !important;
    }

    .editable-row {
      td .ant-radio {
        display: block !important;
      }
    }
  }
}
