@import '../../styles/colors.scss';


.tree-view {
  background-color: #FFF;

  .ant-tree li.selected span {
    color: #1890ff !important;
  }

  .ant-tree.ant-tree-show-line.hide-file-icon li span.ant-tree-switcher-noop {
    background: transparent;
  }

  .ant-tree li span.ant-tree-switcher, .ant-tree li span.ant-tree-iconEle {
    width: 10px;
  }

  .ant-tree-switcher.ant-tree-switcher-noop::before {
    content: "◦";
  }

  .ant-tree li:last-child > span.ant-tree-switcher::before, .ant-tree li:last-child > span.ant-tree-iconEle::before {
    display: inline-block;
  }

  .ant-tree.ant-tree-show-line li:not(:last-child)::before {
    left: 5px;
  }

  .anticon {
    display: none;
  }

  input.ant-input {
    width: 240px;
    margin-left: 10px;
  }

  .icon-plus-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    background-color: #FFF;

    .anticon.anticon-plus-circle {
      margin: 5px 10px;
      font-size: 16px;
      display: block;
    }
  }

  .tree-node-item {
    strong {
      color: $color-error;
    }
  }
}
