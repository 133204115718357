@import './colors.scss';
@import './fonts.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  box-shadow: 0 0 0 1000px #FFF inset;
  font-family: 'SF UI Text Regular';
}

.root-container {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  padding: 24px;

  .page-title {
    margin-bottom: 20px;
    font-size: 24px;
    opacity: 1;
  }
}

.main-view-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

section.ant-layout {
  //background-color: #FFF;
}

.fullwidth {
  width: 100%;
}

h1 {
  font-family: 'SF UI Text Semibold';
  line-height: 32px;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 1.33;
}

h2 {
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0;
  font-family: 'SF UI Text Medium';
  margin-bottom: 10px;
}

h3 {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
  font-family: 'SF UI Text Regular';
  display: inline-block;
}

h1, h2, h3 {
  color: $header-color;
}

.ant-table {
  width: fit-content;
}

.ant-pagination {
  margin: 16px 11px 0 0 !important;
}

.ant-tabs {
  width: calc(100vw - 320px);
}

.ant-tabs-tabpane {
  margin-top: -16px;
  background-color: #fff;
  padding: 20px;
}

table {
  .ant-table-tbody > tr {
    font-family: 'PingFangSC-Regular';
    font-size: 12px;
    line-height: 22px;

    & > td {
      padding: 10px;
      border: none;
    }
  }

  .ant-table-thead > tr {
    background-color: $table-header-color;
    height: 34px;

    & > th {
      padding: 0 10px;
      background-color: inherit;
      border: none;

      & .ant-table-column-title {
        color: rgba(0, 0, 0, 0.65);
        font-family: 'SF UI Text Regular';
        line-height: 22px;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0;
      }
    }
  }
}

.form-section {
  label {
    color: $form-labels-color;
    font-size: 14px;
    font-family: 'SF UI Text Regular';
    line-height: 1.57;

    &:after {
      content: '';
    }
  }

  .ant-input {
    padding: 10px;
    margin-top: 0;
    width: 160px;
    font-family: 'PingFangSC-Regular';
    border: 1px solid $border-input-color;
  }

  .ant-form-item-control {
    line-height: 18px;
  }

  form.ant-form {
    position: relative;
    top: 0%;
    left: 0%;
    transform: none;
    margin: 20px 0 40px;

    &.ant-form-vertical {
      .ant-form-item,
      .ant-form-item-label {
        padding: 0;
      }

      .ant-row.ant-form-item {
        margin: 0 0 10px;
      }
    }

    &.ant-form-horizontal {
      .ant-input {
        font-size: 14px;
      }

      .ant-row.ant-form-item {
        display: flex;
        margin: 0 0 20px;
      }

      .ant-col.ant-form-item-label {
        align-self: center;

        label {
          color: $form-labels-color;
          font-size: 14px;
        }
      }
    }
  }
}

.inline-form {
   display: inline-flex;
   width: 100%;

    .form {
      h4 {
        width: 0;
      }

      h3 {
        padding: 0;
    }

    .form-section {
      border: none;
      display: flex;
      margin: 20px 0 40px;
      padding: 0;
    }

    .form-row {
      margin-right: 15px;

      label {
        justify-content: flex-start;
        width: auto;
      }
    }

    .ant-radio-group.ant-radio-group-outline {
      display: flex;
      align-items: flex-end;
      padding-top: 25px;

      label {
        width: auto;
      }
    }
  }
}

.ant-btn-primary {
  background-color: $primary-button-bgcolor;
  border-color: $primary-border-color;
  color: $primary-button-font-color;
}

.ant-btn-danger {
  background-color: $danger-button-bgcolor;
  border-color: $danger-border-color;
  color: $danger-button-font-color;
}

#nprogress .bar {
  background: $loader-bar-color !important;
  height: 4px !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px $loader-bar-color, 0 0 5px $loader-bar-color !important;
}

#nprogress .spinner-icon {
  display: none;
}
