$selected-color: #1890FF;
$white-with-light-grey: rgba(232, 232, 232, 0.5);
$save-bar-background-color: #ef9696;
$save-bar-border-color: #EEB1B1;
$main-font-color: rgba(0, 0, 0, 0.65);
$light-menu-item-color: rgba(0, 0, 0, 0.09);
$form-labels-color: rgba(0, 0, 0, 0.45);
$border-input-color: #d9d9d9;
$header-color: rgba(0, 0, 0, 0.85);
$header-bg-color: rgba(0, 0, 0, 0.65);
$table-header-color: #ededed;
$ultra-light-grey: rgba(0, 0, 0, 0.05);
$selected: #1890FF;
$sidebar-border-color: #EEEEEE;
$clear-filter-color: #EB0B0B;
$table-header-gray: rgb(231, 230, 230);
$section-border: #d9d9d9;
$color-error: #ff4d4f;
$color-dark-grey: #333;

$primary-button-bgcolor: #285bb9;
$primary-border-color: #285bb9;
$primary-button-font-color: #fff;
$danger-button-bgcolor: #b92828;
$danger-border-color: #b92828;
$danger-button-font-color: #fff;

$loader-bar-color: #4B8E19;

$league-promoted: rgba($loader-bar-color, 0.3);
$league-cl-promoted: rgba($loader-bar-color, 0.8);
$league-europa-promoted: rgba(#ff6102, 0.5);
$league-playoffs: rgba(#ffcc00, 0.3);
$league-relegated: rgba($color-error, 0.3);
