@import "./../../styles/colors";

.editable-table {
  display: flex;
  flex-direction: column;
  width: fit-content;

  .meta-header-wrapper {
    display: flex;
    justify-content: space-between;

    h2 {
      font-size: 20px;
      font-weight: 500;
      line-height: 1.4;
    }
  }

  .ant-select {
    width: 100%;
  }

  .ant-time-picker {
    width: auto;
  }

  .ant-btn.ant-btn-primary {
    align-self: flex-end;
  }

  .ant-table-wrapper {
    border: 1px solid $border-input-color;
    border-radius: 0;
    padding: 0 0 12px;
    margin-bottom: 50px;
    width: fit-content;
  }

  .editable-cell-value-wrap {
    width: 100%;
    height: 32px;
    cursor: text;
    box-sizing: border-box;
    border-bottom: 1px solid $ultra-light-grey;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    position: relative;
    top: 4px;
  }

  .disabled {
    cursor: default;
  }

  .ant-table-row {
    td {
      span {
        cursor: pointer;
      }
    }
  }

  .ant-table-column-title {
    text-transform: capitalize;
  }

  .ant-table-row-cell-break-word {
    padding: 2px 1px;
  }

  .ant-table-bordered .ant-table-body > table {
    border: none;
    padding: 0 20px;

    td, th {
      border-right: none;
    }
  }

  .ant-form-item-control {
    line-height: 32px !important;
  }

  .ant-btn {
    margin-bottom: 6px;
  }

  .editable-row {
    td .ant-radio {
      display: none;
    }

    &.finished {
      td .ant-radio {
        display: block;
      }
    }

    .ant-table-selection-column span {
      position: relative;
      top: 2px;
    }
  }
}

