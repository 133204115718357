@import '../../styles/colors.scss';

.analytics-section {
  .inline-form {
    display: inline-flex;
    width: 100%;

    button {
      width: 100px;
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }

  .analytics {
    list-style: none;

    li {
      padding-top: 6px;

      .row {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-self: center;
        border-bottom: 1px solid $border-input-color;
        padding-bottom: 10px;

        .percentage {
          width: 100%;
          min-width: 140px;
          text-align: left;
          padding-right: 20px;
          margin-right: 20px;

          span {
            font-size: 11px;
            opacity: 0.8;
            margin-right: 10px;

            &:first-child:after {
              content: "-";
              margin-left: 10px;
              margin-right: 0;
            }
          }
        }

        .count {
          font-size: 12px;
          position: relative;
          top: 1px;
          width: 100%;
          min-width: 120px;
          display: flex;
          justify-content: flex-end;

          &.bold {
            font-weight: 700;
            font-size: 12px;
          }

          span {
            font-size: 10px;
            opacity: 0.8;
            font-weight: 400;
            position: relative;
            left: 3px;
            top: 2px;
          }
        }
      }

      &.list-header .row > div {
        font-weight: 700;
        font-size: 12px;
        position: relative;
        top: 0;
        height: auto;
        background-color: initial !important;
      }

      &:not(.list-header):hover {
        background-color: $ultra-light-grey;
      }
    }

    &.matches {
      .date {
        width: 100px;
        font-size: 10px;
      }

      .match {
        width: 300px;
        font-size: 11px;
      }

      .prediction {
        width: 80px;
        text-align: right;
        font-size: 11px;
      }

      .result {
        width: 120px;
        text-align: right;
        font-size: 11px;

        &.bold {
          font-weight: 700;
          font-size: 12px;
        }
      }

      .prediction,
      .result {
        span {
          font-size: 10px;
          opacity: 0.8;
          font-weight: 400;
          position: relative;
          left: 1px;
          top: 0px;
        }
      }
    }
  }
}
