@import '../../styles/colors.scss';

.ranking-container {
  .ranking {
    .filters {
      .filters-row {
        width: 500px;
        border-top: none;

        .filter-clear {
          display: none;
        }
      }
    }

    .tournaments-list-container {
      display: flex;
      flex-direction: column;

      .tournaments-list-position {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 500px;
        margin-left: 20px;
        padding: 10px 20px;
        border-bottom: 1px solid $border-input-color;

        span {
          font-size: 12px;
          text-align: left;
          width: 100%;
          margin-left: 5px;
        }

        span.index {
          font-size: 10px;
          width: 20px;
          position: relative;
          top: 1px;
        }

        .actions {
          display: flex;
          justify-content: space-around;
          width: 100px;

          div {
            cursor: pointer;
            width: 40px;
            opacity: 0.5;

            &:hover {
              opacity: 1;
            }
          }
        }

        &:last-child {
          .actions .down {
            visibility: hidden;
          }
        }

        &:first-child {
          .actions .up {
            visibility: hidden;
          }
        }

        &:hover {
          background-color: $white-with-light-grey;
        }
      }
    }
  }
}
