@import '../../styles/colors.scss';

.attributes-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;

  .ant-menu {
    background-color: transparent;
  }

  .attributes {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;

    .form-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-right: 1px solid $ultra-light-grey;
    }

    .form-section .form {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
    }

    .form-section .form .form-section {
      padding: 20px;
      //box-shadow: 0 0 1px rgba(0, 0, 0, 0.25);
      background-color: #fff;
      margin-bottom: 10px;
    }

    .form-section .form .form-section:last-child {
      margin-bottom: 0;
    }

    .flag-uploader {
      padding: 20px;
      //box-shadow: 0 0 1px rgba(0, 0, 0, 0.25);
      background-color: #fff;
      margin-left: 40px;
      height: 320px;
    }
  }
}
