@font-face {
  font-family: 'SF UI Text Regular';
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/SFUIText-Regular.woff') format('woff');
}

@font-face {
  font-family: 'SF UI Text Medium';
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/SFUIText-Medium.woff') format('woff');
}

@font-face {
  font-family: 'SF UI Text Semibold';
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/SFUIText-Semibold.woff') format('woff');
}