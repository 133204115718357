.table {
  margin-left: -10px;
  margin-right: 20px;
  th { 
    font-size: 12px;
    padding: 5px;
  }

  tr {
    height: 52px;
  }
  td {
    padding: 5px;
  }

  .form-group {
    display: grid;
    grid-template-columns: 154px 140px 140px 90px 0px;
    grid-gap: 5px;

    .ant-select {
      width: 100px !important;
      max-width: 100px !important;
    }

    div.ant-row:last-child {
      margin: 0;
      margin-left: 30px;
    }
  }
}