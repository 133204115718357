@import './../../styles/colors.scss';

.tournaments-container {
  .tournaments-header {
    display: flex;
    justify-content: space-between;

    .page-title {
      width: 100%;
    }
  }

  .teams {
    display: inline-block;
    margin-bottom: 20px;

    .tree-view {
      margin-right: 25px;
      border: 1px solid $border-input-color;

      ul {
        height: 490px;
        overflow: scroll;
      }
    }

   h2,
  .tree-view {
      width: 320px;
    }
  }

  .tournaments-team-entries-section .meta-header-wrapper button.ant-btn.ant-btn-primary {
    display: none;
  }

  .tournaments-fixtures-section,
  .tournaments-team-entries-section {
    .ant-table-layout-fixed table, .ant-table table {
      table-layout: unset;
      border-collapse: collapse;
    }

    .ant-table-body {
      padding: 10px;
    }

    .ant-table-scroll thead > tr {
       th:first-child {
        padding-left: 20px;
      }

      th:last-child {
        padding-right: 20px;
      }
    }

    .ant-table-bordered {
      &.ant-table-fixed-header .ant-table-header > table {
        border: none;
      }

     .ant-table-thead > tr > th {
        border: none;
      }
    }

    table thead th.ant-table-row-cell-break-word {
      text-align: center;
    }
  }

  .tournaments-fixtures-section {
    .editable-table tr td:nth-child(2) div {
      font-size: 9px;
    }
  }

  .team-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: 1px solid #ededed;
    padding: 6px 0;

    .counter {
      opacity: 0.5;
      font-size: 10px;
      width: 30px;
    }

    .team {
      text-align: left;
      width: 500px;
    }

    .acronym {
      text-align: left;
      width: 80px;
      font-weight: 400;
      color: #333;
    }

    .country {
      text-transform: uppercase;
      font-weight: 700;
      text-align: left;
      width: 120px;
    }
  }

  .tournaments-team-entries-section {
    .ant-table-body {
      padding: 0;

      .ant-table-thead > tr > th {
        &:first-child {
          padding-left: 20px;
        }

        &:last-child {
          padding-right: 20px;
        }
      }

      .ant-table-tbody > tr {
        &:first-child {
          td {
            padding-top: 12px;
          }
        }

        & > td {
          padding: 2px 1px;

          &:first-child {
            padding-left: 10px;
          }

          &:last-child {
            padding-right: 10px;
          }
        }
      }
    }
  }

  .tournaments-results-section {
    .editable-table .ant-table-bordered .ant-table-body > table {
      padding: 0 20px 0 10px;
    }
  }

  .editable-table {
    .ant-table-row-selected td {
      background-color: rgba($selected-color, 0.25);
    }

    .ant-table-row-selected {
      td > div,
      td span > input,
      td div > input {
        border-bottom: 1px solid $selected-color;
      }
    }

    .editable-cell-value-wrap {
      height: 36px;
      padding: 5px;
      overflow-y: scroll;
      align-self: center;
      //justify-content: flex-start;
    }
  }

  .tournaments-attributes-section,
  .tournaments-team-entries-section,
  .tournaments-fixtures-section {
    display: flex;

    .form {
      display: flex;
      flex-direction: column;

      .form-section {
        border: none;
        margin: 0;
        padding: 0;
      }

      h3 {
        padding: 0;
      }
    }

    .editable-table .ant-table-wrapper {
      margin-bottom: 30px;
    }

    .tournaments-fixtures-section {
      .editable-table {
        width: 50%;
      }
    }
  }

  .tournaments-fixtures-section {
    flex-direction: column;
  }

  .fixtures-filters {
    margin-bottom: 40px;

    span {
      margin-right: 10px;
    }
  }

  .tournaments-match-details-container {
    padding-left: 40px;

    .ant-tabs {
      width: auto;
    }

    .ant-table {
      td {
        border-bottom: 1px solid $ultra-light-grey;
      }
    }
  }

  .tournaments-matchDetails-section {
    padding: 10px 0;
    margin-left: -20px;

    table {
      margin-left: -10px;
    }

    .span-table {
      margin-top: 0;
    }

    .table-cell-wrapper,
    .span-table .header-item {
      width: 60px;
      text-align: center;
    }

    .table-cell-wrapper:first-child,
    .span-table .header-item:first-of-type {
      width: 170px;
      text-align: left;
    }

    .match-details-team-name {
      width: 170px;
      border: 1px solid $selected-color;
    }

    .match-details-awayTeam h4,
    .match-details-homeTeam h4 {
      color: $selected-color;
    }

    .match-details-awayTeam {
      margin-top: 50px;
    }
  }

  .match-details-info {
    .form-row {
      border-bottom: 1px solid $ultra-light-grey;
      padding-bottom: 6px;

      label {
        opacity: 0.5;
      }

      div {
        font-size: 13px;
      }
    }
  }

  .standings {
    .group-name {
      font-weight: 600;
    }

    .group-name,
    .stage-name {
      display: block;
      margin-bottom: 10px;

      label {
        font-weight: 400;
        font-size: 10px;
        text-transform: uppercase;
        margin-right: 5px;
      }
    }

    .stage-name {
      font-size: 11px;
    }

    table {
      margin-bottom: 60px;

      th {
        background-color: #d9d9d9;
      }

      td {
        white-space: nowrap;
        border-bottom: 1px solid #eee;

        &:nth-child(2) {
          white-space: initial;
        }

        &:nth-child(n+3):nth-child(-n+10) {
          white-space: initial;
          text-align: right;
        }
      }
    }
  }
}
