@import '../../styles/colors.scss';

.save-bar-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 15px 0;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #FFF;
  box-shadow: -1px -5px 20px -8px $header-bg-color;
  z-index: 1;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 140px;
    height: 40px;
  }

  & + div {
    margin-bottom: 100px;
  }
}
