// width:
$tiny: 376px;
$xxs: 480px;
$xs: 640px;
$sm: 768px;
$md: 1024px;
$lg: 1280px;
$xlg: 1440px;
$xxlg: 1920px;

// height:
$tall: 800px;

@mixin breakpoint($class, $size: 'max', $offset: 0) {
  $type: #{$size}-width;

  @if $class == tiny {
    @media ($type: $tiny - $offset) { @content; }
  }

  @if $class == xxs {
    @media ($type: $xxs - $offset) { @content; }
  }

  @else if $class == xs {
    @media ($type: $xs - $offset) { @content; }
  }

  @else if $class == sm {
    @media ($type: $sm - $offset) { @content; }
  }

  @else if $class == md {
    @media ($type: $md - $offset) { @content; }
  }

  @else if $class == lg {
    @media ($type: $lg - $offset) { @content; }
  }

  @else if $class == xlg {
    @media ($type: $xlg - $offset) { @content; }
  }

  @else if $class == xxlg {
    @media ($type: $xxlg - $offset) { @content; }
  }

  @else {
    @warn 'Breakpoint mixin supports: tiny, xxs, xs, sm, md, lg, xlg, xxlg';
  }
}