@import '../../styles/colors.scss';

.login-section {
  width: 100%;
  height: 100vh;
  background-image: url('../../assets/images/background.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.login-page {
  width: 100%;
  height: 100vh;
  background-color: #000;
  opacity: 0.7;
}

.welcome-box {
  height: 100px;
  width: 390px;
  background-image: url('../../assets/images/group.png');
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ant-form.ant-form-horizontal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'SF UI Text Medium';

  .ant-col.ant-form-item-label {
    line-height: normal;
  
    label {
      color: $white-with-light-grey;
      font-size: 13px;
      font-weight: 500;
    }
  }

  .ant-form-item-children {
    a {
      display: block;
      color: $white-with-light-grey;
      font-size: 9px;
      font-weight: 500;
      margin: 5px 0 26px;
      text-align: end;
      font-family: 'SF UI Text Medium';
      line-height: normal;
    }
  }

  .ant-form-item,
  .ant-input {
    width: 256px;
    height: 32px;
    border-radius: 4px;
    border: none;
    margin: 0 auto 32px;
    font-size: 12px;

    &[type="password"] {
      margin-bottom: 0;
    }
  }

  .ant-input {
    padding: 6px;
    margin-top: 4px;    
  }

  .login-button {
    background-color: $selected-color;
    font-family: 'PingFangSC-Regular';
    text-align: center;
    font-size: 14px;
    line-height: 1.57;
    color: #FFF;
    width: 260px;
    height: 32px;
    border-radius: 4px;
    border: none;
    margin-top: 40px;
    cursor: pointer;
  }
}

