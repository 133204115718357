@import '../../styles/colors.scss';

h3.package-used {
  margin-bottom: 20px;
  color: $color-error;
}

.packages {
  .adding-package-close-button {
    margin-bottom: 10px;
  }

  .packages-attributes-section {
    display: flex;

    aside {
      display: flex;
      margin-right: 60px;
      padding-right: 20px;
      border-right: 1px solid $sidebar-border-color;

      .filters {
        background-color: $sidebar-border-color;
        width: 360px;

        .button-container {
          margin-top: 10px;
          text-align: center;
          padding-bottom: 20px;
        }
      }
    }

    .ant-input-number {
      width: 160px;
    }
  }

  .package-item {
    border: 1px dashed gray;
    background-color: #FFF;
    padding: 5px 10px;
    margin-right: 15px;
    margin-bottom: 15px;
    cursor: move;
    border-radius: 4px;
  }

  .list-area {
    width: 100%;
    margin-right: 15px;
    margin-bottom: 15px;
    color: #000;
    padding: 10px;
    text-align: center;
    font-size: 10px;
    border-radius: 4px;

    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: center;
      padding: 0 20px;
      font-size: 14px;
      border-bottom: 1px solid $sidebar-border-color;
      height: 42px;

      span.delete {
        font-weight: 900;
        font-size: 10px;
        cursor: pointer;
      }
    }
  }

  li {
    list-style-type: none;
    padding: 5px 10px;
  }

  .filters-error {
    color: #B90D39;
    text-align: center;
    margin-top: 20px;
    font-size: 16px;
  }

  .drop-placeholder {
    color: $form-labels-color;
  }

  .ant-checkbox-group {
    font-size: 10px !important;
    padding-top: 7px;

    .ant-checkbox-wrapper {
      justify-content: flex-start;
      margin-left: 0;
      margin-bottom: 5px;
      font-size: 11px;
    }
  }
}

.packages-navbar {
  display: flex;
  margin-bottom: 20px !important;
}

.package-title-navbar {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;

  i {
    margin-top: 20px;
    opacity: 0.7;
    font-size: 16px;
    color: $color-error;

    &:hover {
      opacity: 1;
    }
  }
}

