.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  img {
    width: 32px;
    height: 32px;
    animation: bounce 0.75s infinite linear;
    margin-top: 50px;
    opacity: .8;
  }

  .shadow {
    position: relative;
    top: 0;
    left: 0;
    width: 32px;
    height: 12px;
    background: #262626;
    border-radius: 50%;
    opacity: .6;
    animation: shadow 0.75s infinite linear;
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0) rotate(0deg);
  }
  50% {
    transform: translateY(-50px) rotate(180deg);
  }
  100% {
    transform: translateY(0) rotate(360deg);
  }
}

@keyframes shadow {
  0% {
    transform: scale(1);
    opacity: .6;
  }
  50% {
    transform: scale(.4);
    opacity: .3;
  }
  100% {
    transform: scale(1);
    opacity: .6;
  }
}
