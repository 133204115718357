@import '../../styles/colors.scss';

$column-width: 170px;
$column-margin: 4px;

.span-table {
  h2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.4;
    color: $header-color;
    margin-bottom: 20px;
  }

  .span-table-row {
    display: flex;

    .table-cell-wrapper {
      width: $column-width;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-right: $column-margin;
      margin-bottom: 6px;

      .table-cell {
        margin: 0;
        border: 1px solid $border-input-color;
        border-radius: 5px;
        height: 100%;
        width: 100%;
        padding: 7px 10px;

        &--multi:not(:last-child) {
          margin-bottom: 5px;
        }
      }

      div.table-cell {
        cursor: default;
      }

      img {
        width: 100%;
        border: 1px solid $border-input-color;
        border-radius: 5px;
      }
    }
  }

  .header-item {
    width: $column-width;
    margin-right: $column-margin;
    font-size: 14px;
    color: $form-labels-color;
    text-transform: capitalize;
    margin-bottom: 10px;
  }
}
