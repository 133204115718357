@import '../../styles/colors.scss';


.ant-layout-sider {
  background-color: $ultra-light-grey !important;
  border-right: 1px solid $ultra-light-grey;
}

.filters {
  padding: 0 15px;

  h3 {
    color: $main-font-color !important;
    padding-top: 15px;
    font-weight: 600;
  }

  .filters-row {
    width: 100%;
    padding: 20px 0;
    border-top: 1px solid $ultra-light-grey;

    .ant-select {
      width: 100%;
      font-size: 12px;
    }

    label {
      display: flex;
      justify-content: space-between;
      font-family: 'SF UI Text Semibold';
      margin-bottom: 5px;
      text-transform: uppercase;
      font-size: 11px;

      .filter-clear {
        opacity: 0.5;
        cursor: pointer;

        &:hover {
          opacity: 1;
        }

        .anticon {
          color: $clear-filter-color;
        }
      }
    }

    .filter-tree-selector {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $ultra-light-grey;
      height: 32px;
      padding: 0 11px;
      line-height: 30px;
      cursor: pointer;
      background-color: #fff;
      border-radius: 3px;
      overflow: hidden;
      font-size: 12px;

      .anticon {
        font-size: 12px;
        opacity: 0.5;
        display: flex;
        align-items: center;
      }
    }

    .tree-view {
      border-radius: 0 0 3px 3px;
      padding-bottom: 10px;

      .ant-tree {
        padding-left: 5px;
        border-radius: 0 0 3px 3px;
        overflow-y: hidden;
        overflow-x: auto;
        font-size: 12px;

        li {
          &:last-child {
            border-radius: 0 0 3px 3px;
          }
        }
      }
    }
  }
}
