.setup-container {
  .form {
    h3 {
      padding-top: 20px !important;
    }
  }

  .forms-inline-container {
    .form-content {
      display: flex;
      flex-direction: row;
    }
  }
}
